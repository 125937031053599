import React from "react";
import Layout from "../components/layout";

const Success = () => (
  <Layout>
    <div className="container">
      <h1 className="title">Thanks for reaching out!</h1>
      <div className="content">
          <p>I'll get back to you as soon as possible</p>
      </div>
    </div>
  </Layout>
);

export default Success;
